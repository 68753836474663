import { Box } from "@mui/material";

function Pixel({
  index,
  handleClick,
  gridTheme,
  firestorePixels,
  selectedPixels,
  firestorePixelData,
}) {
  // Find the image URL for the current pixel, if it exists
  const pixelData = firestorePixelData?.find((item) => item.pixel_id === index);
  const imageUrl = pixelData ? pixelData.image_url : null;

  return (
    <Box
      key={index}
      onClick={() => handleClick(index)}
      sx={{
        ...gridTheme,
        backgroundColor: firestorePixels.has(index)
          ? "limegreen"
          : selectedPixelColourLogic(selectedPixels, index),
        backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "filter 0.3s ease",
        "&:hover": {
          backgroundColor: firestorePixels.has(index) ? "red" : "white",
          filter: imageUrl === null ? "" : "grayscale(90%)", // Apply grayscale filter on hover
        },
      }}
    ></Box>
  );
}

function selectedPixelColourLogic(selectedPixels, index) {
  return selectedPixels.has(index) ? "grey" : "transparent";
}

export default Pixel;
