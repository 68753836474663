import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';

function TextToggle({ checked, handleChange }) {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={handleChange} />}
      label="Turn text on/off"
    />
  );
}

export default TextToggle;
