import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Badge, Box, Typography } from "@mui/material";

export default function Inventory({ firestorePixels, remainingPixels, theme }) {
  return (
    <>
      <Typography
        variant="h6"
        component="div"
        color="textPrimary"
        sx={{ marginTop: 4 }}
      >
        Pixels:
      </Typography>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}
      >
        <Box>
          <Badge
            badgeContent={firestorePixels.size}
            color="primary"
            overlap="rectangular"
          >
            <AttachMoneyIcon color="action" sx={{ fontSize: "4em" }} />
          </Badge>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: 1 }}
            color={theme === "dark" ? "white" : "rgba(0, 0, 0, 0.87)"}
          >
            Sold
          </Typography>
        </Box>
        <Box marginLeft={"2em"}>
          <Badge badgeContent={remainingPixels} color="secondary" max={9999}>
            <InventoryIcon color="action" sx={{ fontSize: "4em" }} />
          </Badge>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: 1 }}
            color={theme === "dark" ? "white" : "rgba(0, 0, 0, 0.87)"}
          >
            Remaining
          </Typography>
        </Box>
      </Box>
    </>
  );
}
