import React from "react";
import Footer from "../components/Footer";

function Press() {
  return (
    <div className="flex flex-col items-center mt-5">
      <h1 className="text-2xl font-semibold">Press Page</h1>
      <Footer className="mt-8" />
    </div>
  );
}

export default Press;
