import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import TextToggle from "./TextToggle";
import MintMessage from "./MintMessage";
import PixelDrawer from "./PixelDrawer";
import { db, collection, doc, getDoc } from "../firebaseConfig";
import Pixel from "./Pixel";

const PixelGrid = ({
  onPixelClick,
  setFirestorePixels,
  firestorePixels,
  theme,
  userItems,
  setUserItems,
  selectedPixels,
  setSelectedPixels,
}) => {
  const [showText, setShowText] = useState(true);
  const [selectedPixel, setSelectedPixel] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [firestorePixelData, setFirestorePixelData] = useState(null);

  const defaultGridTheme = useMemo(
    () => ({
      border: "0.17px solid rgba(255, 255, 255, 0.3)",
      width: "10px",
      height: "10px",
      backgroundColor: "transparent",
      boxSizing: "border-box",
      position: "relative",
      "@media (max-width: 600px)": {
        width: "calc(100vw / 100)",
        height: "calc(100vw / 100)",
      },
    }),
    []
  );

  const [gridTheme, setGridTheme] = useState(defaultGridTheme);

  const loadDataFromFirestore = useCallback(async () => {
    setLoading(true); // Start loading

    try {
      const ownerDoc = doc(collection(db, "pixels"), "owner");
      const docSnap = await getDoc(ownerDoc);

      if (docSnap.exists()) {
        const data = docSnap.data().userItems;
        const newSelectedPixels = new Set();
        const newUserItems = [];
        const pixelData = [];

        data.forEach((item) => {
          newSelectedPixels.add(item.pixel_id);
          newUserItems.push({
            pixel_id: item.pixel_id,
            wallet_addr: item.wallet_addr,
            image_url: item.image_url, // Ensure image_url is included here
          });
          pixelData.push({
            pixel_id: item.pixel_id,
            wallet_addr: item.wallet_addr, // Make sure wallet_addr is included
            image_url: item.image_url,
          });
        });

        setSelectedPixels(newSelectedPixels);
        setFirestorePixels(newSelectedPixels);
        setFirestorePixelData(pixelData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error loading document from Firebase: ", error);
    } finally {
      setLoading(false); // Finished loading
    }
  }, [
    setLoading,
    setSelectedPixels,
    setFirestorePixels,
    setFirestorePixelData,
  ]);

  useEffect(() => {
    const newGridTheme = {
      ...defaultGridTheme,
      border:
        theme === "dark"
          ? "0.17px solid rgba(255, 255, 255, 0.3)"
          : "0.17px solid #47524E",
      backgroundColor: theme === "dark" ? "transparent" : "black",
    };
    setGridTheme(newGridTheme);

    loadDataFromFirestore();
  }, [theme, defaultGridTheme, loadDataFromFirestore]);

  const walletAddr = "4Nd1mPhgN14WrGHVo33JpuM2ShuH2iHyZiZirZMgQT2B";

  const handleClick = useCallback(
    (index) => {
      setSelectedPixels((prevSelectedPixels) => {
        const newSelectedPixels = new Set(prevSelectedPixels);
        if (newSelectedPixels.has(index)) {
          newSelectedPixels.delete(index);
        } else {
          newSelectedPixels.add(index);
        }

        // Check if the pixel is in firestorePixels before updating userItems
        if (!firestorePixels.has(index)) {
          const newUserItems = newSelectedPixels.has(index)
            ? [...userItems, { wallet_addr: walletAddr, pixel_id: index }]
            : userItems.filter((item) => item.pixel_id !== index);

          setUserItems(newUserItems);
        }

        // Always update selectedPixel based on the click
        if (firestorePixels.has(index)) {
          const clickedUserItem = firestorePixelData.find(
            (item) => item.pixel_id === index
          );
          console.log("Clicked User Item:", clickedUserItem); // Debug
          setSelectedPixel({
            pixel_id: index,
            wallet_addr: clickedUserItem?.wallet_addr, // Debug
            image_url: clickedUserItem?.image_url,
          });
        } else {
          setSelectedPixel(null); // Clear selectedPixel if not in firestorePixels
        }

        if (onPixelClick) {
          onPixelClick(index);
        }

        return newSelectedPixels;
      });
    },
    [
      onPixelClick,
      userItems,
      firestorePixels,
      firestorePixelData,
      setSelectedPixels,
      setUserItems,
      walletAddr,
    ]
  );

  const handleToggleChange = () => {
    setShowText(!showText);
  };

  const handleCloseDrawer = () => {
    setSelectedPixel(null);
  };

  const handleClearSelection = () => {
    setSelectedPixels(new Set());
    setUserItems([]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "1000px",
        margin: "0 auto",
        position: "relative",
      }}
    >
      {/* Conditional rendering of spinner or MintMessage */}
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 10,
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color={theme === "dark" ? "primary" : "success"} />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 10,
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showText && <MintMessage theme={theme} />}
        </Box>
      )}

      {/* Typography and Clear Button */}
      <Box
        sx={{
          mt: 3,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "16px", // Add padding to align with the grid
          marginBottom: "8px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="textPrimary"
          sx={{ marginLeft: "16px" }} // Move Typography to the left margin
        >
          Please select a pixel for info
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearSelection}
        >
          Clear Selection
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(100, 1fr)",
          gridTemplateRows: "repeat(100, 1fr)",
          width: "100%",
          maxWidth: "1000px",
          height: "1000px",
          gap: 0,
          backgroundColor: theme === "dark" ? "transparent" : "black",
          "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(100, 1fr)",
            gridTemplateRows: "repeat(100, 1fr)",
            width: "100%",
            height: "100vw",
          },
        }}
      >
        {[...Array(10000)].map((_, index) => (
          <Pixel
            key={index}
            index={index}
            handleClick={handleClick}
            gridTheme={gridTheme}
            firestorePixels={firestorePixels}
            selectedPixels={selectedPixels}
            firestorePixelData={firestorePixelData}
          />
        ))}
      </Box>

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <TextToggle checked={showText} handleChange={handleToggleChange} />
      </Box>

      <PixelDrawer
        selectedPixel={selectedPixel}
        handleCloseDrawer={handleCloseDrawer}
        theme={theme}
      />
    </Box>
  );
};

export default PixelGrid;
