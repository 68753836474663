import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";
import PixelGrid from "../components/PixelGrid";
import Footer from "../components/Footer";
import Inventory from "../components/Inventory";
import SelectionBox from "../components/SelectionBox";

function Home({ theme }) {
  const [firestorePixels, setFirestorePixels] = useState(new Set());
  const [userItems, setUserItems] = useState([]);
  const [selectedPixels, setSelectedPixels] = useState(new Set());

  const handlePixelClick = (index) => {
    // Handle pixel click logic if needed
  };

  const cards = [
    {
      title: "Become a Minter",
      description:
        "Join the MillionDollarMint and become part of blockchain and internet history. Secure your spot on the grid and connect with like-minded individuals.",
    },
    {
      title: "Unique Opportunity",
      description:
        "Mint your pixel on the blockchain and own a piece of the web. This unique opportunity offers you a chance to be part of something unprecedented.",
    },
    {
      title: "Exclusive Club",
      description:
        "Gain access to the exclusive Minters Club, where you can network with forward-thinking businesses and individuals. Enjoy benefits and airdrops reserved for original holders.",
    },
  ];

  const totalPixels = 10000;
  const remainingPixels = totalPixels - firestorePixels.size;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className="flex flex-col items-center mt-1 px-4 overflow-hidden">
      <section
        className="relative mt-5 flex justify-center items-start"
        style={{ height: "min-content", width: "100%", overflow: "hidden" }}
      >
        <PixelGrid
          onPixelClick={handlePixelClick}
          firestorePixels={firestorePixels}
          setFirestorePixels={setFirestorePixels}
          theme={theme}
          userItems={userItems}
          setUserItems={setUserItems}
          selectedPixels={selectedPixels}
          setSelectedPixels={setSelectedPixels}
        />
        {userItems.length > 0 && !isMobile && (
          <SelectionBox
            userItems={userItems}
            setUserItems={setUserItems}
            selectedPixels={selectedPixels}
            setSelectedPixels={setSelectedPixels}
          />
        )}
      </section>

      {userItems.length > 0 && isMobile && (
        <SelectionBox
          userItems={userItems}
          setUserItems={setUserItems}
          selectedPixels={selectedPixels}
          setSelectedPixels={setSelectedPixels}
        />
      )}
      <Container maxWidth="md" className="mt-5">
        <Grid container spacing={4}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Inventory
        firestorePixels={firestorePixels}
        remainingPixels={remainingPixels}
        theme={theme}
      />
      <Footer />
    </div>
  );
}

export default Home;
