import { Typography } from "@mui/material";

export default function MintMessage({ theme }) {
  const gridTextColor = theme === "dark" ? "white" : "white";
  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        fontWeight="bold"
        mb={1}
        lineHeight={1.2}
        color={gridTextColor}
      >
        Mint on the blockchain,
        <br />
        <span>become part of internet history.</span>
      </Typography>
      <Typography mb={2} color={gridTextColor}>
        1,000,000 pixels, permanently etched on the blockchain
      </Typography>
      <Typography variant="caption" mb={2} color={gridTextColor}>
        1 pixel = $1
      </Typography>
    </>
  );
}
