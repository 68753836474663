import { Box, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function PixelDrawer({
  selectedPixel,
  handleCloseDrawer,
  theme,
}) {
  const textColour = theme === "dark" ? "white" : "white";

  return (
    <Drawer
      anchor="right"
      open={!!selectedPixel}
      onClose={handleCloseDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: "300px",
          padding: "16px",
          backgroundColor: theme === "dark" ? "#002929" : "#202120", // Background color set here
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color={textColour}>
          Pixel Details
        </Typography>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon color="white" />
        </IconButton>
      </Box>
      {selectedPixel && (
        <Box
          sx={{ mt: 2, maxHeight: "calc(100vh - 160px)", overflowY: "auto" }}
        >
          <Typography variant="body1" color={textColour}>
            <strong>Pixel ID:</strong> {selectedPixel.pixel_id}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }} color={textColour}>
            <strong>Wallet Address:</strong> {selectedPixel.wallet_addr}
          </Typography>
          {selectedPixel.image_url && (
            <>
              <Typography variant="body1" color={textColour}>
                <strong>Image URL:</strong> {selectedPixel.image_url}
              </Typography>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <img
                  src={selectedPixel.image_url}
                  alt={`Pixel ${selectedPixel.pixel_id}`}
                  style={{ maxWidth: "100%", borderRadius: "8px" }}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Drawer>
  );
}
