import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/Home";
import MintList from "./pages/MintList";
import Updates from "./pages/Updates";
import FAQ from "./pages/FAQ";
import Press from "./pages/Press";
import Header from "./components/Header";
import { lightTheme, darkTheme } from "./theme";
import "./index.css";

function App() {
  const [theme, setTheme] = useState("dark"); // Set initial theme to 'dark'

  useEffect(() => {
    document.documentElement.classList.add(theme);
    document.documentElement.classList.remove(
      theme === "dark" ? "light" : "dark"
    );
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <Router>
        <div
          className={`flex flex-col items-center w-full overflow-hidden ${theme}`}
        >
          <Header theme={theme} toggleTheme={toggleTheme} />
          <div className="w-full">
            <Routes>
              <Route
                exact
                path="/"
                element={<Home theme={theme} toggleTheme={toggleTheme} />}
              />
              <Route path="/mintlist" element={<MintList />} />
              <Route path="/updates" element={<Updates />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/press" element={<Press />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
