import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Container,
  Button,
  Skeleton,
} from "@mui/material";
import Footer from "../components/Footer";
import { db, collection, doc, getDoc } from "../firebaseConfig";

function MintList() {
  const [cardsData, setCardsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const cardsPerPage = 8;

  const formatWalletAddress = (wallet) => {
    if (!wallet) return "No wallet address";
    return `${wallet.slice(0, 5)}...${wallet.slice(-3)}`;
  };

  const loadDataFromFirestore = useCallback(async () => {
    setLoading(true); // Start loading

    try {
      const ownerDoc = doc(collection(db, "pixels"), "owner");
      const docSnap = await getDoc(ownerDoc);

      if (docSnap.exists()) {
        const pixelData = docSnap.data().userItems || [];

        // Transform data
        const transformedData = pixelData.map((item) => ({
          id: item.pixel_id || "N/A",
          wallet: formatWalletAddress(item.wallet_addr) || "No wallet address",
          image: item.image_url || "",
          description: `Pixel ${item.pixel_id || "N/A"} on the canvas.`,
        }));

        setCardsData(transformedData);
        setTotalPages(Math.ceil(transformedData.length / cardsPerPage));
      }
    } catch (error) {
      console.error("Error loading document from Firebase: ", error);
    } finally {
      setLoading(false); // Finished loading
    }
  }, []);

  useEffect(() => {
    loadDataFromFirestore();
  }, [loadDataFromFirestore]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const paginatedCards = cardsData.slice(startIndex, startIndex + cardsPerPage);

  return (
    <>
      <Container className="flex flex-col items-center mt-5 mb-8 w-full max-w-1000 px-4 md:px-8">
        <Typography
          variant="h4"
          component="h1"
          className="text-2xl font-semibold mb-5 dark:text-white"
        >
          Mint List
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className="mb-8 dark:text-white"
        >
          The Mint List showcases all the minted pixels on the MillionDollarMint
          canvas. Each pixel represents a unique spot on the canvas, purchased
          and owned by various users. Below is a detailed list of minted pixels
          along with their respective owners.
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          {loading
            ? Array.from({ length: cardsPerPage }).map((_, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    className="bg-transparent shadow-none dark:text-white"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <CardContent>
                      <Typography variant="h5" component="div">
                        <Skeleton width="60%" />
                      </Typography>
                      <Typography variant="body2">
                        <Skeleton width="80%" />
                      </Typography>
                      <Typography variant="subtitle2">
                        <Skeleton width="40%" />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            : paginatedCards.map((card) => (
                <Grid item xs={12} sm={6} md={3} key={card.id}>
                  <Card
                    className="bg-transparent shadow-none dark:text-white"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    {card.image !== "" ? (
                      <CardMedia
                        component="img"
                        image={card.image}
                        alt={`Mint #${card.id}`}
                        style={{
                          width: "100%", // Ensure the image covers the width of the card
                          height: 140, // Fixed height
                          objectFit: "cover", // Ensure the image covers the area without distortion
                        }}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={140}
                      />
                    )}
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Mint #{card.id}
                      </Typography>
                      <Typography variant="body2">
                        {card.description}
                      </Typography>
                      <Typography variant="subtitle2">
                        Minted by {card.wallet}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
        </Grid>
        <div className="pagination-controls mt-4 flex justify-center items-center gap-4">
          <Button
            variant="contained"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          <Typography variant="body1" component="span" className="mx-4">
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      </Container>
      <Footer className="mt-8" /> {/* Optional margin-top for Footer */}
    </>
  );
}

export default MintList;
