import React from "react";
import { Link } from "react-router-dom";
import MintoshiLogo from "../assets/mintoshi.svg"; // Ensure the path is correct

function Footer() {
  return (
    <footer className="w-full flex flex-col items-center justify-center text-center text-sm opacity-60 mb-10 mt-5">
      <div className="flex justify-center gap-5 mb-2">
        <Link
          to="https://twitter.com"
          className="cursor-pointer hover:text-white"
        >
          X (Twitter)
        </Link>
        <Link to="https://t.me" className="cursor-pointer hover:text-white">
          Community (Telegram)
        </Link>
      </div>
      <p className="mb-2">
        MillionDollarMint © {new Date().getFullYear()}. All rights reserved.
      </p>
      <p className="mb-2">
        I am not responsible for the content of external sites. Images featured
        on homepage are © of their respective owners.
      </p>
      <div className="flex flex-col items-center border border-transparent p-4">
        <span className="opacity-100">Created by</span>
        <img
          src={MintoshiLogo}
          alt="Mintoshi"
          className="h-4 w-auto opacity-100 mt-1"
        />
      </div>
    </footer>
  );
}

export default Footer;
