import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import { db, collection, doc, getDoc } from "../firebaseConfig";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";

const PAGE_SIZE = 9;

function Updates() {
  const [updates, setUpdates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchUpdates = async () => {
      const docRef = doc(collection(db, "updates"), "data");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data().updates;
        // Sort the data by date in descending order
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setUpdates(data);
        setTotalPages(Math.ceil(data.length / PAGE_SIZE));
      } else {
        console.log("No such document!");
      }
    };

    fetchUpdates();
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedUpdates = updates.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  return (
    <>
      <Container className="flex flex-col items-center mt-5 w-full max-w-1000 px-4 md:px-8">
        <Typography
          variant="h4"
          component="h1"
          className="text-2xl font-semibold mb-5 dark:text-white"
        >
          Updates
        </Typography>
        <AnimatePresence>
          <Grid container spacing={4} marginTop={0.5}>
            {paginatedUpdates.map((update) => (
              <Grid item xs={12} sm={6} md={4} key={update.id}>
                <motion.div
                  key={update.id} // Ensure unique key for each motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card
                    className="bg-transparent shadow-none dark:text-white"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h5" component="div" gutterBottom>
                        {update.title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {update.date}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {update.content}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </AnimatePresence>
        <div className="flex justify-between mt-5 w-full">
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            variant="contained"
          >
            Previous
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            variant="contained"
          >
            Next
          </Button>
        </div>
      </Container>
      <Footer className="mt-8" />
      {/* <PushUpdatesButton /> */}
    </>
  );
}

export default Updates;
