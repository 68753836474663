import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import millionDollarMintLight from "../assets/milliondollarmint.svg";
import millionDollarMintDark from "../assets/milliondollardark.svg";

function Header({ theme, toggleTheme }) {
  const [logo, setLogo] = useState(millionDollarMintLight);

  useEffect(() => {
    setLogo(theme === "dark" ? millionDollarMintDark : millionDollarMintLight);
  }, [theme]);

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center w-full max-w-4xl mb-5 px-4 mt-2">
      <img src={logo} alt="MillionDollarMint" className="h-10 mb-2 sm:mb-0" />
      <div className="flex items-center gap-3 sm:gap-5">
        <NavLink
          exact
          to="/"
          className={({ isActive }) =>
            `cursor-pointer transition-opacity ${
              isActive ? "opacity-100" : "opacity-45"
            } hover:opacity-100`
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/mintlist"
          className={({ isActive }) =>
            `cursor-pointer transition-opacity ${
              isActive ? "opacity-100" : "opacity-45"
            } hover:opacity-100`
          }
        >
          Mint List
        </NavLink>
        <NavLink
          to="/updates"
          className={({ isActive }) =>
            `cursor-pointer transition-opacity ${
              isActive ? "opacity-100" : "opacity-45"
            } hover:opacity-100`
          }
        >
          Updates
        </NavLink>
        <NavLink
          to="/faq"
          className={({ isActive }) =>
            `cursor-pointer transition-opacity ${
              isActive ? "opacity-100" : "opacity-45"
            } hover:opacity-100`
          }
        >
          FAQ
        </NavLink>
        <NavLink
          to="/press"
          className={({ isActive }) =>
            `cursor-pointer transition-opacity ${
              isActive ? "opacity-100" : "opacity-45"
            } hover:opacity-100`
          }
        >
          Press
        </NavLink>
        {/* <a href="https://t.me/" className="bg-[#7DD8B5] text-[#000F08] px-4 py-2 rounded hover:bg-primary hover:text-secondary transition-colors font-satoshi inline-block">Mint Now</a> */}
        <ThemeToggle checked={theme === "dark"} toggleTheme={toggleTheme} />
      </div>
    </div>
  );
}

export default Header;
