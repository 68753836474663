import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  styled,
  Typography,
  Modal,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FireStoreButton from "./FireStoreButton";

export default function SelectionBox({
  userItems,
  setUserItems,
  setSelectedPixels,
}) {
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPixel, setSelectedPixel] = useState(null);
  const [file, setFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [uploadLoading] = useState(false);

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const handleDelete = (index) => {
    const pixelId = userItems[index].pixel_id;
    setUserItems((prevItems) => prevItems.filter((_, i) => i !== index));
    setSelectedPixels((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.delete(pixelId);
      return newSelected;
    });
  };

  const handleOpenModal = (pixel) => {
    setSelectedPixel(pixel);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setFile(null);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSaveImage = () => {
    if (!file || !selectedPixel) return;

    // const updatedItem = userItems.find(
    //   (item) => item.pixel_id === selectedPixel
    // );
    // const newItem = {
    //   ...updatedItem,
    //   file, // Store the file object temporarily
    // };

    setUserItems((prevItems) =>
      prevItems.map((item) =>
        item.pixel_id === selectedPixel ? { ...item, file } : item
      )
    );

    setSnackbarMessage("Image file saved locally!");
    setSnackbarSeverity("success");
    handleCloseModal();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752, ml: "2px" }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={dense}
              onChange={(event) => setDense(event.target.checked)}
            />
          }
          label="Minimize"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={secondary}
              onChange={(event) => setSecondary(event.target.checked)}
            />
          }
          label="View details"
        />
      </FormGroup>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Selected Pixel
        </Typography>
        <Demo>
          <List dense={dense}>
            {userItems.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <IconButton onClick={() => handleOpenModal(item.pixel_id)}>
                    {item.file ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <FileUploadIcon />
                    )}
                  </IconButton>
                </ListItemAvatar>
                <ListItemText
                  primary={`Pixel ID: ${item.pixel_id}`}
                  secondary={
                    secondary ? `Wallet Address: ${item.wallet_addr}` : null
                  }
                />
              </ListItem>
            ))}
          </List>
        </Demo>
        <FireStoreButton userItems={userItems} />{" "}
        {/* Pass userItems to FireStoreButton */}
      </Grid>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {uploadLoading ? (
            <CircularProgress color={"primary"} />
          ) : (
            <>
              <Typography variant="h6" component="h2">
                Upload image for pixel {selectedPixel}
              </Typography>
              <input type="file" onChange={handleFileChange} />
              <Button
                onClick={handleSaveImage}
                variant="contained"
                color="primary"
              >
                Save Image
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
