import React, { useState } from "react";
import { Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import {
  db,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "../firebaseConfig";

const FireStoreButton = ({ userItems }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    const upload_url =
      "https://milliondollarmint.com/.netlify/functions/server/upload";

    try {
      const response = await fetch(upload_url, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        return data.url;
      } else {
        console.error("Upload failed", data);
        return null;
      }
    } catch (error) {
      console.error("Upload failed", error);
      return null;
    }
  };

  const prepareDataForFirestore = async () => {
    const preparedData = await Promise.all(
      userItems.map(async (item) => {
        let imageUrl = null;
        if (item.file) {
          imageUrl = await uploadImage(item.file);
        }

        // Ensure that all values are defined and valid
        return {
          pixel_id: item.pixel_id || "",
          wallet_addr: item.wallet_addr || "",
          image_url: imageUrl || item.image_url || null, // Ensure image_url is null if undefined
        };
      })
    );

    return preparedData.filter((item) => item.pixel_id); // Ensure pixel_id is present
  };

  const pushToFirebase = async () => {
    try {
      setUploadLoading(true);
      const pixelsCollection = collection(db, "pixels");
      const ownerDoc = doc(pixelsCollection, "owner");

      const finalUserItems = await prepareDataForFirestore();

      const docSnap = await getDoc(ownerDoc);

      if (docSnap.exists()) {
        // Merge new userItems with existing ones
        const existingUserItems = docSnap.data().userItems || [];
        const updatedUserItems = [...existingUserItems, ...finalUserItems];

        await updateDoc(ownerDoc, { userItems: updatedUserItems });
      } else {
        // If the document does not exist, create it with userItems
        await setDoc(ownerDoc, { userItems: finalUserItems });
      }

      setOpenSuccess(true);
      window.location.reload();
    } catch (error) {
      setErrorMessage(error.message);
      setOpenError(true);
      console.error("Error writing document to Firebase: ", error);
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#7DD8B5",
          color: "#000F08",
          mt: 2,
          "&:hover": {
            backgroundColor: "#000F08",
            color: "#7DD8B5",
          },
        }}
        onClick={pushToFirebase}
        disabled={uploadLoading} // Disable button during upload
      >
        {uploadLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Mint Now!"
        )}
      </Button>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
      >
        <Alert
          onClose={() => setOpenSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Data successfully written to Firebase!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
      >
        <Alert
          onClose={() => setOpenError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error writing document to Firebase: {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FireStoreButton;
