import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBh7TovteE8jtTTyoMxjYhsl6tk_aM4zqY",
  authDomain: "mintlist-cd702.firebaseapp.com",
  projectId: "mintlist-cd702",
  storageBucket: "mintlist-cd702.appspot.com",
  messagingSenderId: "352573126054",
  appId: "1:352573126054:web:4a5f4983aec11090ee8b67",
  measurementId: "G-1GCTZ88PP0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, doc, setDoc, getDoc, getDocs, updateDoc };
