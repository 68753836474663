import React from 'react';
import { Switch } from '@mui/material';

const ThemeToggle = ({ checked, toggleTheme }) => {
  return (
    <div className="relative inline-block">
      <Switch checked={checked} onChange={toggleTheme} />
      {checked && (
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-dark-start to-dark-end z-[-1] rounded-full"></div>
      )}
    </div>
  );
};

export default ThemeToggle;
