import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../components/Footer";

const faqData = [
  {
    question: "Why become a Minter?",
    answer:
      "Minters who join MDM will become part of blockchain and internet history. It’s a way of unifying Web2 + Web3 world’s together, as well as pushing on a viral content marketing strategy.",
  },
  {
    question: "History in the making",
    answer:
      "Anyone who joins the mint club is going to be given the guarantees of being on web2 for 10 years minimum, whilst also having 2 proof of ownership transactions via the Solana blockchain.\n\nOnce the project sells out, a portion will be allocated to heavily investing into the marketing of the project.\n\nAll original holders will not only be a part of history, but the chance to get an airdrop and maybe more…",
  },
  {
    question: "Unseen, Unique Work",
    answer:
      "It’s an opportunity to be a part of history, do something that has never been seen before, and when the project sells out, everyone will be airdropped their official mint via the wallet address they paid with.",
  },
  {
    question: "Minters Only Club",
    answer:
      "All Minters will be granted access to the Mint Club, an exclusive minters only place where like minded people and some of the most forward thinking and driven businesses can connect.",
  },
  {
    question: "Introduction",
    answer:
      "MillionDollarMint is a tribute to the iconic 2005 piece of internet history, http://www.milliondollarhomepage.com\n\nIt is a simple, similar concept to the project, except with the use of the blockchain, MDM allows users to purchase pixels, priced at $1 each, at a minimum of 100 pixels per purchase, once the pixels are all sold out, they will be minted on the blockchain and airdropped to the respective holders.",
  },
  {
    question: "Why MDM?",
    answer:
      "MillionDollarMint is a tribute to the iconic 2005 piece of internet history, http://www.milliondollarhomepage.com\n\nIt is a simple, similar concept to the project, except with the use of the blockchain, MDM allows users to purchase pixels, priced at $1 each, at a minimum of 100 pixels per purchase, once the pixels are all sold out, they will be minted on the blockchain and airdropped to the respective holders.",
  },
  {
    question: "How much does it cost?",
    answer:
      "It is a simple, similar concept to the project, except with the use of the blockchain, MDM allows users to purchase pixels, priced at $1 each, at a minimum of 100 pixels per purchase, once the pixels are all sold out, they will be minted on the blockchain and airdropped to the respective holders.",
  },
  {
    question: "Where do I mint from?",
    answer:
      "It is a simple, similar concept to the project, except with the use of the blockchain, MDM allows users to purchase pixels, priced at $1 each, at a minimum of 100 pixels per purchase, once the pixels are all sold out, they will be minted on the blockchain and airdropped to the respective holders.",
  },
  {
    question: "Where do payments go?",
    answer:
      "All payments go to the wallet: \n\nYou can track the wallet here, we have also added a progress bar to show how close it is to being filled.",
  },
  {
    question: "What can I include?",
    answer:
      "Other than the image itself, all mints can include a web address, token holder info, username and message. This is subject to your own liking and preference.",
  },
  {
    question: "How does approval work?",
    answer:
      "You message our bot the image and size you want, our AI will then evaluate your design and if approved, you will be notified and given the option to pay.",
  },
  {
    question: "What happens after I pay?",
    answer:
      "Once paid, you will receive a receipt to show your proof of purchase. Your image will then soon go on the website after.",
  },
  {
    question: "What happens when it sells out?",
    answer:
      "When MDM sells out, there is no more pixels to buy and we will create the contract with a certified developer partner, such as CertiK, to allow all the original users to be airdropped their image, essentially minting it onto the blockchain itself.",
  },
  {
    question: "Introduction",
    answer:
      "Million Dollar Mint - (One Pixel at a time) or (Mint your pixel on the blockchain, become a part of internet history)",
  },
  {
    question: "Concept overview",
    answer:
      "Inspired by the iconic Milliondollarhomepage.com.\nSelling each pixel for $1 on a digital canvas minted on a blockchain. The minimum purchase is 100 pixels.",
  },
  {
    question: "Why Million Dollar Mint?",
    answer:
      "Own a piece of digital real estate on the blockchain.\nBe part of a project that echoes the legendary MillionDollarHomepage.\nBlockchain ensures permanence and transparency.",
  },
  {
    question: "The Minting Process",
    answer:
      "To become a minter, Go to our telegram bot (@MintNow_bot)\nUpload your image for AI approval\nAI approval: Ensure compliance with our guidelines (no nudity, illegal, or unethical content).\nOnce approved, receive a reference address to complete the payment.\nUpon payment, your pixels are minted and receipts issued.\nAll minters are welcome to join the Mintmunity.\nThe first 100 minters will receive 0.1% of the project once it sells out.",
  },
  {
    question: "Why Blockchain?",
    answer:
      "Transparency: Immutable record of ownership\nSecurity: Decentralized ledger prevents tampering.\nLegacy: Ensures your contribution is preserved for future generations.",
  },
  {
    question: "Content Guidelines",
    answer:
      "Accepted content: Innovative, unique and compliant with ethical standards.\nProhibited content: Nudity, unethical content and illegal images.",
  },
  {
    question: "Outreach",
    answer:
      "Utilise platforms such as Telegram, Twitter and Instagram.\nReferral program: Encourage people to start referring new users using the incentive scheme of 10% commission per mint.\nPartnerships: Collaborate with related projects (Crypto Wallets, Solana coins, Crypto news pages etc..)\nEngage with relevant forums, Reddit threads and other niche communities.",
  },
  {
    question: "Vision",
    answer:
      "Short term: Sell the first 100,000 pixels\nLong term: Create a fully minted, interactive digital art piece.\nBuild a vibrant, engaged community around the project.",
  },
];

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container className="flex flex-col items-center mt-5 w-full max-w-1000 px-4 md:px-8">
        <h1 className="text-2xl font-semibold mb-5 dark:text-white">
          Frequently Asked Questions
        </h1>
        <Grid container spacing={2} justifyContent="center">
          {faqData.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                className="bg-transparent shadow-none"
                sx={{
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  className={`dark:text-white ${
                    expanded === `panel${index}` ? "opacity-100" : "opacity-30"
                  }`}
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "&.MuiAccordionSummary-root": {
                      minHeight: "48px",
                    },
                  }}
                >
                  <Typography variant="h6">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails className="dark:text-white">
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer className="mt-8" />
    </>
  );
}

export default FAQ;
